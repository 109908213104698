<template lang="pug">
#static-template.step-component(:class="pageType") 
  icon.icon(v-if="icon", :data="generateIcon()", :original="true")
  div(:class="pageType", v-html="header")
  div(:class="pageType", v-html="body")
  button.button(:class="pageType", v-if="button", @click="gotoNext()") {{ button }}
  p.footer(v-if="footer", :class="pageType") {{ footer }}
  p.footer(v-else, :class="pageType") If you have any questions, please contact us at #[a(href="mailto:kit.help@ro.co") kit.help@ro.co].
</template>

<script>
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    body: {
      type: String,
    },
    icon: {
      type: String,
    },
    footer: {
      type: String,
    },
    button: {
      type: String,
    },
    pageType: {
      type: String,
    },
  },

  data() {
    return {}
  },

  created() {
    this.$app.forceLandscape = true
  },

  mounted() {},

  computed: {},

  watch: {},

  methods: {
    generateIcon,
    gotoNext,
  },
  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function generateIcon() {
  return require(`@icon/${this.icon}`)
}
function gotoNext() {
  const eventTitle = this.title
  const eventProperties = {
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    sku: this.$store.getters['user/getSku'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    end_date: new Date().toISOString(),
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })

  this.$emit('complete', true)
}
</script>
